import { forwardRef, memo } from 'react';
import { OutBoundLink } from '../../components/outboundLink';
type AboutProps = {
    bgColor?: string;
    id: string;
};

const About = forwardRef<HTMLElement, AboutProps>(
    ({ bgColor = 'woodyBrown', id }: AboutProps, ref) => (
        <section
            ref={ref}
            id={id}
            className={`relative z-10 grid justify-center px-6 sm:px-32 xl:px-72 py-16 bg-${bgColor}`}
        >
            <div className="prose sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                <h1>Om Mundu</h1>
                <p className="lead">
                    Demens er en av vår tids største samfunnsutfordringer, og
                    antallet rammede øker raskt i takt med en aldrende
                    befolkning. Over{' '}
                    <OutBoundLink href="https://demenskartet.no">
                        100.000
                    </OutBoundLink>{' '}
                    mennesker i Norge lever med demens i dag, og med dem står
                    400.000 pårørende overfor en hverdag preget av utfordringer,
                    usikkerhet og ofte stillhet.
                </p>
                <div className="grid xl:grid-cols-2 xl:gap-8">
                    <div>
                        <p>
                            Men minnene forsvinner ikke – de trenger bare hjelp
                            til å vekkes.
                        </p>
                        <p>
                            Men minnene forsvinner ikke – de trenger bare hjelp
                            til å vekkes. Mundu er en forskningsbasert
                            opplevelsesteknologi utviklet i samarbeid med NKS
                            Olaviken alderspsykiatriske sykehus, helsepersonell
                            og spesialister innen aldring og kognisjon. Gjennom
                            personlige bilder og visuelle stimuli bidrar Mundu
                            til å:
                        </p>
                    </div>
                    <div>
                        <p>📸 Vekke minner og skape gjenkjennelse</p>
                        <p>
                            👪 Forsterke båndet mellom den som er rammet og
                            pårørende
                        </p>
                        <p>🗣 Fasilitere samtaler og bryte stillheten</p>
                        <p>
                            🏡 Skape trygghet og ro – både hjemme og på
                            institusjon
                        </p>
                        <p>
                            Mundu er utviklet for mennsker med mild kognitiv
                            svikt eller mild til moderat demens, spesielt de som
                            også opplever uro, angst eller depresjon. Den gir
                            pårørende og helsepersonell et konkret verktøy for å
                            skape meningsfulle møter og gode øyeblikk i
                            hverdagen.
                        </p>
                        <p>
                            <b>Gjør en forskjell i dag.</b> Oppdag hvordan Mundu
                            kan bringe minnene tilbake og gi en bedre hverdag
                            for dem som trenger det mest.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    ),
);
About.displayName = 'About';

export default About;
