import { forwardRef, memo } from 'react';
import { AppStoreBadgeSVG } from 'src/assets/svg';
import { AZURE_STORAGE_URL } from 'src/config';

type ProductProps = {
    bgColor?: string;
    id: string;
};
const Product = forwardRef<HTMLElement, ProductProps>(
    ({ bgColor = 'woodyBrown', id }: ProductProps, ref) => {
        const ipadUrl = `${AZURE_STORAGE_URL}/landingpage-content/ipad_air.png`;
        const iphoneUrl = `${AZURE_STORAGE_URL}/landingpage-content/munduapp3.png`;
        const macbookUrl = `${AZURE_STORAGE_URL}/landingpage-content/macbook.png`;
        const googlPlayBadgeUrl = `${AZURE_STORAGE_URL}/landingpage-content/google_play_badge.png`;
        return (
            <section
                ref={ref}
                id={id}
                className={`relative z-10 flex flex-col items-center px-6 sm:px-32 xl:px-72 pt-16 bg-${bgColor}`}
            >
                <div className="prose prose-peach sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                    <div className="grid grid-cols-5 pb-16 justify-center">
                        {/**
                        <h1 className="col-span-5">Millioner av låter</h1>
                        <div className="productPoints col-span-2 largeTablet:col-span-2">
                            <p>
                                Enkelt for helsepersonell å tonesette personlige
                                bilder med musikk.
                            </p>
                            <p>
                                Enorm katalog med lisensiert musikk. Alle finner
                                musikken de elsker.
                            </p>
                            <p>
                                Kartlegging av brukerens musikalske livshistorie
                            </p>
                            <p>Krever ingen opplæring. </p>
                            <p>Enkelt og trygt. </p>
                        </div>
                        <img
                            src={macbookUrl}
                            alt="mundu_on_macbook"
                            className="col-span-3 largeTablet:col-span-3 pl-2 max-w-full max-h-9/16 justify-self-center"
                        />

                        <h1 className="col-span-5">
                            Verdens enkleste strømmetjeneste
                        </h1>
                        <div className="productPoints col-span-2">
                            <p>
                                Enkelt for bruker å spille av personlige
                                videoer. Online og offline.
                            </p>
                            <p>
                                Tar musikken ut av terapirommet og inn i
                                hverdagslivet
                            </p>

                            <p>Stimulerer til den gode samtalen</p>
                            <p>Tilpasset svekket syn og gamle fingre</p>
                        </div>
                        <img
                            src={ipadUrl}
                            alt="mundu_on_macbook"
                            className="col-span-3 pl-2 max-w-full max-h-9/16 justify-self-center"
                        />
                         */}
                        <h1 className="col-span-5">Bilder fra levd liv</h1>
                        <p className="col-span-5">
                            <b>Last ned appen og prøv gratis i 30 dager!</b>
                        </p>
                        <div className="productPoints col-span-2">
                            <p>
                                Enkelt og trygt å dele bilder for pårørende og
                                familie.
                            </p>
                            <p>
                                Gjør familiebildene digital med mobilkameraet...
                            </p>
                            <p>
                                ...eller legg til bilder direkte fra
                                kamerarullen
                            </p>
                            <p>
                                Spesialtilpasset brukeropplevelse med sikker
                                lagring i skyen.
                            </p>
                        </div>
                        <img
                            src={iphoneUrl}
                            alt="mundu_on_iphone"
                            className="col-span-3 pl-2 max-w-full max-h-9/16 justify-self-center"
                        />
                    </div>
                </div>
                <div className="grid justify-center prose prose-peach max-w-none">
                    <p className="lead text-center pb-8">
                        Mundu er tilgjengelig på iPhone og iPad.
                    </p>
                </div>
                <div className="grid justify-center">
                    <div className="flex justify-center space-x-4 pb-8">
                        <button className="pointer-events-none h-12 md:h-16">
                            <AppStoreBadgeSVG />
                        </button>
                        {/**
                        <button className="pointer-events-none h-12 md:h-16">
                            <img
                                className="object-contain h-12 md:h-16"
                                src={googlPlayBadgeUrl}
                                alt="google_play_badge"
                            />
                        </button>
                         */}
                    </div>
                </div>
                <hr className="w-full" />
                <div className=" grid justify-center w-full prose max-w-none">
                    {/**
                    <div className=" legalAttribution text-center pt-0">
                        Apple, the Apple Logo, iPad, iPhone and MacBook Pro are
                        trademarks of Apple Inc. Android, Google Play and the
                        Google Play logo are trademarks of Google LLC.
                    </div>
                     */}
                    <div className=" legalAttribution text-center pt-0">
                        Apple, the Apple Logo, iPad, iPhone and MacBook Pro are
                        trademarks of Apple Inc.
                    </div>
                </div>
            </section>
        );
    },
);

Product.displayName = 'Product';

export default Product;
