import {
    useState,
    useEffect,
    useRef,
    MutableRefObject,
    useCallback,
    useMemo,
} from 'react';
import { LandingPageHeader } from '../components';
import { useHistory } from 'react-router-dom';
import {
    ValueProposition,
    PromoVideo,
    About,
    Contact,
    Testimonials,
    Product,
    News,
} from './sections';
import { AboutPromoVideo } from './sections';
import { useNewsPosts } from 'src/view/hooks/sanity';

export enum ContentSection {
    ValueProposition = 'ValueProposition',
    PromoVideo = 'PromoVideo',
    AboutPromoVideo = 'AboutPromoVideo',
    Product = 'Product',
    Testimonials = 'Testimonials',
    About = 'About',
    Contact = 'Contact',
    News = 'News',
}
type HeaderColors = {
    textColor: string;
    activeTextColor: string;
    backgroundColor: string;
};

type SectionColors = {
    bgColor: string;
    headerColors: HeaderColors;
};

const SECTIONS: Record<ContentSection, SectionColors> = {
    ValueProposition: {
        bgColor: 'lightPeach',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'lightPeach',
        },
    },
    PromoVideo: {
        bgColor: 'white',
        headerColors: {
            textColor: 'lightPeach',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'transparent',
        },
    },
    AboutPromoVideo: {
        bgColor: 'lightPeach',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'lightPeach',
        },
    },
    Product: {
        bgColor: 'lightPeach',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'lightPeach',
        },
    },
    Testimonials: {
        bgColor: 'vanillaWhite',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'lightPeach',
        },
    },
    About: {
        bgColor: 'woodyBrown',
        headerColors: {
            textColor: 'starkWhite',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'woodyBrown',
        },
    },

    Contact: {
        bgColor: 'peachOrange',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'peachOrange',
        },
    },
    News: {
        bgColor: 'lightPeach',
        headerColors: {
            textColor: 'woodyBrown',
            activeTextColor: 'mongooseGray',
            backgroundColor: 'lightPeach',
        },
    },
};

export const LandingPage = () => {
    const [currentSection, setCurrentSection] = useState<
        ContentSection | undefined
    >(undefined);
    const [headerColors, setHeaderColors] = useState<HeaderColors | undefined>(
        undefined,
    );
    const history = useHistory();
    const animate = history.action === 'POP';
    const headerRef = useRef<HTMLElement>(null);
    const valuePropositionRef = useRef(null);
    const promoVideoRef = useRef(null);
    const aboutPromoVideoRef = useRef(null);
    const productRef = useRef(null);
    const testimonialsRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);
    const newsRef = useRef(null);
    const newsPosts = useNewsPosts(3);

    const sectionRefs: {
        section: ContentSection;
        ref: MutableRefObject<HTMLElement | null>;
    }[] = useMemo(() => {
        return [
            {
                section: ContentSection.ValueProposition,
                ref: valuePropositionRef,
            },
            { section: ContentSection.PromoVideo, ref: promoVideoRef },
            {
                section: ContentSection.AboutPromoVideo,
                ref: aboutPromoVideoRef,
            },
            { section: ContentSection.Product, ref: productRef },
            { section: ContentSection.Testimonials, ref: testimonialsRef },
            { section: ContentSection.News, ref: newsRef },
            { section: ContentSection.About, ref: aboutRef },
            { section: ContentSection.Contact, ref: contactRef },
        ];
    }, []);

    const ObserverCallback = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const header = headerRef.current;
            if (header) {
                const headerHeight = header.getBoundingClientRect().height / 2;
                const selected = entries.find(entry => {
                    const { top, bottom } = entry.boundingClientRect;
                    return top < headerHeight && headerHeight <= bottom;
                });
                if (selected && selected.target.id !== currentSection) {
                    setCurrentSection(selected.target.id as ContentSection);
                    setHeaderColors(
                        SECTIONS[selected.target.id as ContentSection]
                            .headerColors,
                    );
                } else if (!selected && currentSection) {
                    setCurrentSection(undefined);
                }
            }
        },
        [currentSection],
    );

    useEffect(() => {
        const observer = new IntersectionObserver(ObserverCallback, {
            root: document.getElementById('LandingPage'),
            rootMargin: '-38.25px',
            threshold: [0],
        });
        sectionRefs.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });
        return () => {
            sectionRefs.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [ObserverCallback]);

    const navItems =
        newsPosts.length !== 0
            ? [
                  { id: ContentSection.News, name: 'Aktuelt' },
                  { id: ContentSection.About, name: 'Om' },
              ]
            : [{ id: ContentSection.About, name: 'Om' }];

    return (
        <div id="LandingPage" className="parallax">
            <LandingPageHeader
                containerId="LandingPage"
                headerRef={headerRef}
                colors={headerColors}
                scrollItems={true}
                navItems={navItems}
            />
            <ValueProposition
                ref={valuePropositionRef}
                id={ContentSection.ValueProposition}
                bgColor="lightPeach"
                animate={animate}
            />
            <PromoVideo
                ref={promoVideoRef}
                id={ContentSection.PromoVideo}
                bgColor="lightPeach"
            />
            <AboutPromoVideo
                ref={aboutPromoVideoRef}
                id={ContentSection.AboutPromoVideo}
                bgColor="lightPeach"
            />
            {/**
            <Testimonials
                ref={testimonialsRef}
                id={ContentSection.Testimonials}
                bgColor="peachOrange"
            />
             */}
            {newsPosts.length !== 0 && (
                <News
                    ref={newsRef}
                    id={ContentSection.News}
                    bgColor="lightPeach"
                    newsPosts={newsPosts}
                />
            )}
            <About
                ref={aboutRef}
                id={ContentSection.About}
                bgColor="woodyBrown"
            />
            <Product
                ref={productRef}
                id={ContentSection.Product}
                bgColor="lightPeach"
            />
            <Contact
                ref={contactRef}
                id={ContentSection.Contact}
                bgColor="peachOrange"
            />
            {/* <Footer textColor="woodyBrown" backgroundColor="peachOrange" /> */}
        </div>
    );
};
