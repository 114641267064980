import { Footer } from '../components/footer';
import { ScrollToTopOnMount, LandingPageHeader } from '../components';
import { Screen } from 'src/view/routes/Router';

type Props = {
    showHeader?: boolean;
    showFooter?: boolean;
};
export const Privacy = ({ showHeader = true, showFooter = true }: Props) => {
    return (
        <div>
            <ScrollToTopOnMount />
            {showHeader && (
                <LandingPageHeader
                    colors={{
                        textColor: 'starkWhite',
                        activeTextColor: 'mongooseGray',
                        backgroundColor: 'woodyBrown',
                    }}
                    scrollItems={false}
                    navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
                />
            )}
            <div className="grid justify-center w-full bg-woodyBrown pt-32 pb-16 px-6 sm:px-32 lg:px-56">
                <div className="prose md:prose-lg xl:prose-xl">
                    <h1>Personvern</h1>
                    <h2>1. Introduksjon</h2>
                    <p>
                        Mundu Dementiacare AS (“Mundu”) leverer en app-løsning.
                        Denne personvernerklæringen beskriver hvordan Mundu
                        samler inn informasjonen din når du bruker tjenestene
                        våre, hvilken informasjon vi samler inn, hvordan den
                        brukes og hvem informasjonen deles med. Dette betyr at
                        vi er ansvarlige for å overholde personopplysningsloven
                        i gjeldende jurisdiksjon, den generelle
                        personvernforordningen (GDPR) og andre gjeldende
                        personvernbestemmelser. Informasjonen som Mundu samler
                        inn, bruker og deler inkluderer blant annet
                        registreringsinformasjonen din, metadata, stedsdata og
                        data om enheten din.
                    </p>
                    <p>
                        Informasjonen din lagres og behandles i samsvar med
                        kravene som er angitt i General Data Protection
                        Regulation (GDPR), som nærmere forklart nedenfor. Vi vil
                        bare beholde informasjonen din så lenge det er nødvendig
                        i samsvar med formålene som er beskrevet i dette
                        reglementet. Våre brukere og andre tredjeparter har ikke
                        tilgang til personlig informasjon som kan brukes til å
                        kontakte eller identifisere en registrert uten den
                        registrertes forutgående eksplisitte samtykke /
                        godkjenning, eller på annen måte uten juridisk grunnlag
                        for enhver gjeldende behandlingsaktivitet. Denne
                        personvernerklæringen angir vilkårene for behandling og
                        bruk av personlig informasjon gitt av deg (eller hentet
                        fra deg) til Mundu når du bruker tjenestene våre. Ved å
                        bruke våre tjenester godtar du våre vilkår for bruk og
                        vilkårene og fremgangsmåtene som er beskrevet i denne
                        personvernerklæringen.
                    </p>
                    <p>
                        Denne erklæringen gjelder for brukere av våre tjenester
                        hvor som helst i verden, inkludert brukere av våre
                        apper, nettsteder, funksjoner eller andre tjenester.
                    </p>
                    <h2>2. Innsamling og bruk av informasjon og data.</h2>
                    <p>
                        Når du bruker våre tjenester, vil det være visse
                        opplysninger og data (samlet kalt &quot;Data&quot;) som
                        du vil sende til oss, eller som vi vil samle inn fra
                        deg. I de følgende avsnittene nedenfor vil vi angi
                        viktige detaljer angående innsamlingen og bruken av
                        Dataene dine.
                    </p>
                    <h2>3 Data vi samler inn og hvorfor</h2>
                    <h3>Data vi samler inn når du bruker Mundu:</h3>

                    <p>For sporingssystem for programvarefeil:</p>
                    <ul className="list-inside">
                        <li>
                            Brukeragent (User agent): System - og nettleserdata
                            og nettleserutvidelser
                        </li>
                    </ul>
                    <p>For brukerautentisering:</p>
                    <ul className="list-inside">
                        <li>
                            Brukeridentitet (fornavn, etternavn, e-postadresse)
                            som ligger lagret i din nettleser frem til du lukker
                            nettleservinduet.
                        </li>
                    </ul>
                    <p>For musikkstrømming:</p>
                    <ul className="list-inside">
                        <li>
                            Brukeragent (User agent): System - og nettleserdata
                            og nettleserutvidelser
                        </li>
                    </ul>

                    <h3>Data vi samler inn under brukerregistrering:</h3>
                    <ul className="list-inside">
                        <li>Fornavn og etternavn</li>
                        <li>Telefonnummer</li>
                        <li>E-postadresse</li>
                        <li>Brukeragent</li>
                        <li>Unik enhets-id</li>
                        <li>Navn på institusjon</li>
                        <li>Navn på avdeling </li>
                    </ul>
                    <h3>
                        Data vi samler inn når du blar gjennom noen av
                        nettsidene våre
                    </h3>

                    <p>For sporingssystem for programvarefeil:</p>
                    <ul className="list-inside">
                        <li>
                            Brukeragent (User agent): System - og nettleserdata
                            og nettleserutvidelser
                        </li>
                    </ul>

                    <p className="font-bold">
                        Vi samler ikke inn data når du laster opp innhold utover
                        data du deler med Mundu.
                    </p>
                    <h3>
                        Erklæring om bruk av lokal lagring av data i din
                        nettleser.
                    </h3>
                    <p>
                        Når du er innlogget som bruker i Mundu benytter vi det
                        som kalles Session Storage for å lagre din
                        brukeridentitet for autentisering. Session Storage er et
                        lokalt lagringsmedium i din nettleser. Vi benytter dette
                        for å holde deg innlogget så lenge du holder
                        nettleservinduet åpent. Ved lukking av nettleservinduet
                        vil brukeridentiteten din slettes fra nettleseren, og du
                        vil dermed måtte logge inn på ny.
                    </p>
                    <p>
                        Under opprettelse av video i Mundu, benytter vi Local
                        Storage for å lagre data og metadata knyttet til din
                        video. Dataene forblir lagret i din nettleser frem til
                        du velger å lage en ny video. Formålet med dette er at
                        du skal kunne forlate nettleseren og fortsette der du
                        stoppet på et senere tidspunkt.
                    </p>
                    <h3>Tredjepartsinnsamling</h3>
                    <p>
                        For å få tjenestene til å fungere for de formålene som
                        er tiltenkt, er det ofte nødvendig å dele noen av
                        dataene dine med tredjepartsleverandører. Vi kan dele
                        dataene dine med Mundu sine tilknyttede selskaper eller
                        tilknyttede enheter som tilbyr tjenester eller utfører
                        databehandling på våre vegne eller for
                        datasentralisering og / eller logistikk; med
                        leverandører, konsulenter og andre tjenesteleverandører
                        som trenger tilgang til slik informasjon for å utføre
                        arbeid på våre vegne og / eller for å gjøre det mulig
                        for dem å tilby tjenestene du ba om; og med tredjeparter
                        tillater du oss å dele informasjon med for eksempel
                        andre apper eller nettsteder som integreres med API-et
                        eller tjenestene våre, eller de med en API eller
                        tjeneste som vi integrerer med. Vi kan også tillate
                        andre enheter og tredjeparter å bruke
                        informasjonskapsler og lignende sporingstjenester på
                        tjenestene våre.
                    </p>
                    <p>
                        Vi kan dele informasjonen vi samler inn om deg med
                        tredjeparter som beskrevet nedenfor eller som beskrevet
                        på tidspunktet for innsamling eller deling, inkludert
                        følgende:
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Tredjepart</th>
                                <th>Data</th>
                                <th>Formål</th>
                                <th>Lagring</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Microsoft Azure</td>
                                <td>
                                    Bilder og brukerdata (fornavn, etternavn,
                                    epost, navn på instutisjon)
                                </td>
                                <td>
                                    Lagring av bilder og bruker-autentisering
                                </td>
                                <td>Nederland, Europa</td>
                            </tr>
                            <tr>
                                <td>MongoDB Atlas</td>
                                <td>Metadata og brukerdata</td>
                                <td>
                                    Database for lagring av brukerdata og
                                    video-metadata
                                </td>
                                <td>Nederland, Europa</td>
                            </tr>
                            <tr>
                                <td>Sentry</td>
                                <td>Brukeragent</td>
                                <td>
                                    For å kunne oppdage og spore feil som
                                    oppstår i web- og mobilappen
                                </td>
                                <td>Iowa, USA</td>
                            </tr>
                            <tr>
                                <td>7Digital</td>
                                <td>Brukeragent, enhets-ID</td>
                                <td>For å kunne tilby musikk</td>
                                <td>Europa</td>
                            </tr>
                            <tr>
                                <td>SendGrid</td>
                                <td>
                                    Fornavn, etternavn, navn på institusjon,
                                    e-post, passord for første gangs pålogging
                                </td>
                                <td>
                                    For å kunne invitere registrerte brukere av
                                    Mundu og sende varslinger, via e-post.
                                </td>
                                <td>
                                    Herndon, VA, USA; Las Vegas, NV, USA; og
                                    Chicago, IL, USA
                                </td>
                            </tr>
                            <tr>
                                <td>Mixpanel</td>
                                <td>Metadata og brukerdata</td>
                                <td>
                                    For å kunne analysere generell bruk i
                                    utviklingsøyemed
                                </td>
                                <td>Nederland, Europa</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>4. Generell informasjon vedrørende dine data</h3>
                    <h3>Data</h3>
                    <p>
                        Data lastet opp til plattformen vår vil være
                        tilgjengelig for alle medlemmer i den relaterte
                        organisasjonen med de rette tillatelsene. De vil kunne
                        se, redigere eller dele dataene du laster opp. Dataene
                        dine er ikke offentlig tilgjengelig for andre brukere og
                        personer utenfor organisasjonen din, med mindre du eller
                        noen i organisasjonen har delt tilgang til slike data.
                        Ved å dele data med andre medlemmer og brukere,
                        erkjenner du at det er mulig for andre medlemmer og
                        brukere som har tilgang til dataene dine å reprodusere
                        og lagre innhold eksternt, og uten ditt samtykke. I et
                        slikt tilfelle er du klar over at dataene dine kan bli
                        utnyttet av medlemmene og brukerne som har tilgang til
                        dataene, underlagt deres eneste administrasjon og
                        ansvar. Slik utnyttelse kan omfatte: å kopiere,
                        modifisere, lage avledede verk av, distribuere,
                        offentlig vise, offentlig utføre og på annen måte
                        utnytte slike data i alle formater og
                        distribusjonskanaler som nå er kjent eller heretter
                        utarbeidet.
                    </p>
                    <p>
                        Vi forbeholder oss retten til å umiddelbart fjerne, uten
                        forvarsel, data eller brukergenerert innhold som bryter
                        med Sluttbrukeravtalen.
                    </p>
                    <h3>Overholdelse</h3>
                    <p>
                        Vi kan dele ut dataene dine ved forespørsel fra
                        kompetent myndighet hvis vi mener utlevering er påkrevd
                        av gjeldende lov, forskrift eller juridisk prosess; med
                        offentlige tjenestepersoner, offentlige etater eller
                        andre tredjeparter hvis vi mener at dine handlinger ikke
                        er i samsvar med lisensavtalen med gjeldende kunder,
                        vilkår for bruk, personvernregler eller for å beskytte
                        rettighetene, eiendommen eller sikkerheten til Mundu
                        eller andre. Vi vil varsle brukerne om slik utlevering
                        som nevnt her, hvis slik meddelelse (etter vår beste
                        kunnskap) ikke er forbudt ved lov.
                    </p>
                    <h2>5. Hvordan vi behandler dataene dine</h2>
                    <h3>5.1 Hvordan vi behandler dataene dine</h3>

                    <p>
                        Vi har strenge databehandlingsavtaler med alle våre
                        leverandører / databehandlere med hensyn til all
                        behandling av personopplysninger på vegne av Mundu for å
                        sikre at all behandling av personopplysninger utføres i
                        samsvar med gjeldende databeskyttelseslovgivning.
                    </p>
                    <p>
                        Når det gjelder dataene dine, er Mundu dataansvarlig.
                        Databehandlerne vi engasjerer skal kun behandle
                        personopplysninger etter instruksjonene fra oss og
                        strengt i samsvar med slike instruksjoner. Mundu kan på
                        hvilket som helst tidspunkt ha avtaler med
                        databehandlere i andre deler av verden, inkludert i
                        Amerika, Asia eller Europa, som vil behandle dine data
                        på våre vegne og i samsvar med gjeldende personvernlov.
                        Vi vil kun overføre data ut av EU i samsvar med
                        beslutning C-311/18 (Schrems II), eller eventuelle
                        standardklausuler godkjent av EU-kommisjonen.
                    </p>
                    <p>
                        Ved å registrere en bruker hos oss eller ved å bruke
                        noen av tjenestene våre, erkjenner du at dataene dine
                        kan overføres, lagres, behandles og brukes i EU og andre
                        land der tredjeparts tjenesteleverandører opererer på
                        våre vegne. Du bekrefter også at du er klar over at
                        personvern- og databeskyttelseslovene i noen av disse
                        landene kan variere fra lovene i landet du bor. Der
                        dette er tilfelle, vil vi ta passende forholdsregler for
                        å beskytte din personlige informasjon i henhold til
                        denne erklæringen.
                    </p>
                    <h3>5.2 Formålet for behandlingen av dine data</h3>
                    <p>
                        Mundu kan ikke bruke dataene dine til andre formål enn
                        de som er angitt i denne personvernserklæringen. Vi
                        lagrer kun dataene dine så lenge det er nødvendig for å
                        oppfylle det spesifikke formålet med behandlingen.
                    </p>
                    <h3>5.3 Sikring av dataene dine</h3>
                    <p>
                        En beskrivelse av informasjonssikkerhetstiltakene som er
                        gjennomført for å beskytte dine personopplysninger
                        følger nedenfor:
                    </p>
                    <p>Personal- og tilgangskontroll</p>
                    <ul className="list-inside">
                        <li>
                            Bare autorisert personale med signerte
                            konfidensiellavtaler kan gi, modifisere eller
                            tilbakekalle tilgang til et informasjonssystem som
                            bruker eller huser personlig informasjon om våre
                            kunder og brukere. Autorisert personell vil kun ha
                            tilgang til dataene som trengs for å tilby og
                            forbedre tjenesten vår. Vår webklient benytter
                            to-faktor autentisering.
                        </li>
                    </ul>

                    <p>Databeskyttelse:</p>
                    <ul className="list-inside">
                        <li>
                            Vi vurderer alltid egnede sikkerhetstiltak for å
                            beskytte dataene dine. Som hashing av passord og
                            kryptering av data under transport og hvile.
                        </li>
                    </ul>

                    <p>Kontraktsmessig kontroll</p>
                    <ul className="list-inside">
                        <li>
                            Vi inngår databehandlingsavtaler med tredjeparter
                            som behandler data på våre vegne.
                        </li>
                        <li>
                            Vi sørger for at personlig informasjon behandles
                            utelukkende i samsvar med kundens instruksjoner
                            (kontroll av instruksjonene);
                        </li>
                    </ul>
                    <p>Logisk tilgangskontroll</p>
                    <ul className="list-inside">
                        <li>Dataene dine er logisk adskilt fra andre data.</li>
                        <li>
                            Databasen vår er beskyttet mot uautorisert tilgang
                            ved hjelp av passord, 2-faktor autentisering og
                            rollebasert autorisasjon, eller ved en
                            tilgangsnøkkel som er lagret kryptert ved hjelp av
                            en libsodium sealed box hos GitHub i kombinasjon med
                            bruk av IP-adresseliste.
                        </li>
                        <li>
                            Lagringsmedium for bilder er beskyttet mot
                            uautorisert tilgang ved hjelp av passord og
                            rollebasert autorisasjon, eller ved en 512-bit
                            tilgangsnøkkel som er lagret kryptert ved hjelp av
                            en libsodium sealed box hos GitHub.
                        </li>
                    </ul>
                    <p>Forretningskontinuitet</p>
                    <ul className="list-inside">
                        <li>
                            Vi sørger for at personlig informasjon er beskyttet
                            mot utilsiktet ødeleggelse eller tap
                            (tilgjengelighetskontroll); ved å enten selv ta
                            sikkerhetskopier eller gjennom noen av
                            underbehandlerne våre.
                        </li>
                    </ul>
                    <p>Data i overføring over internett</p>
                    <ul className="list-inside">
                        <li>
                            Dine data er sikret med kryptografiske protokoller,
                            enter TLS eller SSL under overføring via internett.
                        </li>
                    </ul>
                    <h2>6. Kommunikasjon mellom deg og Mundu</h2>
                    <h3>E-post</h3>
                    <p>
                        Vi kan sende deg e-post angående kontoen din,
                        tjenesteoppdateringer, undersøkelser angående verktøyene
                        våre, webplattformen og tjenesten, varslinger om
                        aktivitet knyttet til din konto, og endringer i
                        vilkårene for bruk og personvernregler.
                    </p>
                    <p>
                        Informasjonen din lagres og behandles i samsvar med
                        kravene som er angitt i General Data Protection
                        Regulation (GDPR), som nærmere forklart nedenfor. Vi vil
                        bare beholde informasjonen din så lenge det er nødvendig
                        i samsvar med formålene som er beskrevet i dette
                        reglementet. Våre brukere og andre tredjeparter har ikke
                        tilgang til personlig informasjon som kan brukes til å
                        kontakte eller identifisere en registrert uten den
                        registrertes forutgående eksplisitte samtykke /
                        godkjenning, eller på annen måte uten juridisk grunnlag
                        for enhver gjeldende behandlingsaktivitet. Denne
                        personvernerklæringen angir vilkårene for behandling og
                        bruk av personlig informasjon gitt av deg (eller hentet
                        fra deg) til Mundu når du bruker tjenestene våre. Ved å
                        bruke våre tjenester godtar du våre vilkår for bruk og
                        vilkårene og fremgangsmåtene som er beskrevet i denne
                        personvernerklæringen.
                    </p>
                    <p>
                        Denne erklæringen gjelder for brukere av våre tjenester
                        hvor som helst i verden, inkludert brukere av våre
                        apper, nettsteder, funksjoner eller andre tjenester.
                    </p>
                    <h2>7. Sletting av data</h2>

                    <h3>E-post</h3>
                    <p>
                        Data vil bli fjernet fra serverne våre når de er slettet
                        fra tjenestene våre, men de kan forbli i hurtigbuffer
                        eller sikkerhetskopier i opptil 90 dager etter sletting.
                        Vi kontrollerer ikke hva brukerne gjør med mottatte
                        data, og du bekrefter at du er klar over at dette.
                        Bruken skjer på egen risiko som nevnt i
                        Sluttbrukeravtalen. Mundu anbefaler derfor at du ikke
                        deler innhold som du ikke vil at noen skal lagre. Vi er
                        ikke ansvarlige for innholdet lastet opp til tjenestene
                        våre eller handlingene til brukerne våre.
                    </p>
                    <p>
                        Hvis du imidlertid ser innhold som bryter med våre
                        vilkår, kan du kontakte oss på support@mundu.no, og vi
                        vil iverksette rimelige tiltak for å fjerne denne
                        informasjonen fra arkivene våre.
                    </p>
                    <p>
                        Hvis du vil slette kontoen din som er opprettet på vår
                        nettplattform ber vi deg om å benytte slettetjenesten på
                        nettplattformen.
                    </p>
                    <p>
                        Merk at kontodataene dine fremdeles kan være til stede i
                        sikkerhetskopier i opptil 30 dager, til disse
                        sikkerhetskopiene er slettet.
                    </p>
                    <p>
                        Når det gjelder analytiske data, for eksempel
                        krasjrapportering, vil vi bare beholde disse på våre
                        tredjeparts servere så lenge de er nødvendige for å
                        oppfylle formålene beskrevet ovenfor.
                    </p>
                    <h2>8. Korrigere og motta informasjon om dataene dine</h2>
                    <p>
                        Hvis du oppdager at informasjon om deg i tjenesten har
                        feil eller mangler, vennligst bruk de gjeldende
                        funksjonene i selve tjenestene for å endre denne
                        informasjonen. Hvis dette ikke er mulig eller hvis du
                        ikke får til å gjøre det, kan du kontakte oss på
                        support@mundu.no.
                    </p>
                    <p>
                        Med forbehold om lovgivning som krever konfidensialitet,
                        har du rett til å motta generell informasjon om hvordan
                        Mundu bruker dine personlige data. Hvis du ønsker å
                        motta slik informasjon, kan du kontakte oss på
                        support@mundu.no. For å utøve din &quot;rett til
                        sletting&quot; (EUs GDPR, artikkel 17) eller &quot;
                        retten til dataportabilitet&quot; (EU GDPR, artikkel
                        20), kan du bruke slettetjenesten på vår nettplattform..
                    </p>
                    <p>
                        Hvis du vil slette kontoen din som er opprettet på vår
                        nettplattform ber vi deg om å benytte slettetjenesten på
                        nettplattformen.
                    </p>
                    <p>
                        Merk at kontodataene dine fremdeles kan være til stede i
                        sikkerhetskopier i opptil 30 dager, til disse
                        sikkerhetskopiene er slettet.
                    </p>
                    <p>
                        Når det gjelder analytiske data, for eksempel
                        krasjrapportering, vil vi bare beholde disse på våre
                        tredjeparts servere så lenge de er nødvendige for å
                        oppfylle formålene beskrevet ovenfor.
                    </p>
                    <h2>9. Merknader og oppdateringer</h2>
                    <p>
                        Mundu forbeholder seg retten til å endre eller justere
                        personvernerklæringen og Sluttbrukeravtalen når som
                        helst uten forvarsel. Din fortsatte bruk av tjenestene
                        våre vil utgjøre din aksept av eventuelle reviderte
                        avtaler. Hvis vi gjør betydelige endringer i denne
                        personvernerklæringen, vil vi varsle deg. Vi anbefaler
                        at du med jevne mellomrom sjekker disse avtalene for
                        endringer. Hvis du velger å bruke tjenestene våre, vil
                        enhver tvist om personvern være underlagt dette
                        personvernsreglementet og våre vilkår for bruk. Med
                        mindre annet er oppgitt, gjelder vår nåværende
                        personvernsreglement all informasjon vi samler inn om
                        deg og kontoen din, inkludert annen data og informasjon
                        som vi samler inn når du bruker tjenestene våre.
                    </p>
                    <p className="italic">
                        Hvis du har noen bekymringer vedrørende personvern hos
                        Mundu, kan du kontakte oss på support@mundu.no, så
                        prøver vi å løse det.
                    </p>
                    <h2>10. Valg av lov og jurisdiksjon</h2>
                    <p>
                        Norges lover skal regulere bruksvilkårene og denne
                        personvernserklæringen. Hvis du har en tvist med Mundu,
                        skal du først kontakte oss ved å sende en e-post til
                        hello@mundu.no. Eventuelle tvister som oppstår i henhold
                        til Sluttbrukeravtalen og denne personvernserklæringen
                        skal søkes avgjort i minnelighet. Med mindre en minnelig
                        løsning kan oppnås, skal tvisten være gjenstand for
                        ordinær rettsbehandling. Verneting for tvister skal være
                        Oslo tingrett.
                    </p>
                    <p>
                        Norges lover skal regulere bruksvilkårene og denne
                        personvernserklæringen. Hvis du har en tvist med Mundu,
                        skal du først kontakte oss ved å sende en e-post til
                        hello@mundu.no. Eventuelle tvister som oppstår i henhold
                        til Sluttbrukeravtalen og denne personvernserklæringen
                        skal søkes avgjort i minnelighet. Med mindre en minnelig
                        løsning kan oppnås, skal tvisten være gjenstand for
                        ordinær rettsbehandling. Verneting for tvister skal være
                        Oslo tingrett.
                    </p>
                    <p className="footnote float-right">
                        <span className="font-bold">Sist oppdatert: </span>
                        Denne personvernerklæringen ble sist oppdatert
                        10.06.2021
                    </p>
                </div>
            </div>
            {showFooter && (
                <Footer textColor="starkWhite" backgroundColor="woodyBrown" />
            )}
        </div>
    );
};
